@import 'design/template/mixin_templates';

/*-----------------------------
    GeNeRAL
-----------------------------*/

*, html {
    outline: 0;
    margin: 0;
    padding: 0;
}

body {
    font-size: 12px;
    line-height: 1.2;
    list-style-position: inside;
    font: .75em verdana, arial, sans-serif;
    background-color: #333;
    background-attachment:fixed;
    color: #000;
}

a img {
    border: none;
}

@include titreBaseSize();

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    margin: 0;
    padding: 0;
}

h1, .h1 {
    font-size: 3em;
}

h2, .h2 {
    font: 1.3em arial, sans-serif;
    font-weight: 700;
    margin: 0 0 20px;
    padding: 15px 0 5px;
    border-bottom: 2px dotted #bbb;
}

h4, .h4 {
    margin-bottom: 10px;
}

body #wrapper nav#nav-static-principal, body #wrapper #contentpage_right nav#nav-static-principal, body #wrapper #contentpage_left #boutiqueaccueil, body #wrapper #contentpage_center #boutiqueaccueil, body #wrapper #contentpage_right #boutiqueaccueil, body #footer, div#wrapper div#header nav#nav-principal li ul, #form1 legend {
    display: none;
}

iframe {
    max-width: 100%;
}

/*-----------------------------
    HEADER
-----------------------------*/

div#wrapper div#header {
    position: relative;
    min-height: 200px;
    z-index: 9998;
}

div#wrapper div#headercontent {
    height: 120px;
    padding: 0 20px 20px;
}

div#wrapper div#headercontent h1,
div#wrapper div#headercontent .h1 {
    padding-top: 20px;
}

div#wrapper div#headercontent a {
    color: #FFF;
    text-decoration: none;
    font-family: arial, sans-serif;
}

.logo {
    display: inline-block;
    img {
        max-width: 100%;
        max-height: 100px;
    }
}

.slogan {
    min-height: 20px;
    font-size: 2em;
    position: absolute;
    top: 35px;
    width: 70%;
    text-align: right;
    font-family: arial, sans-serif;
    color: #fff;
    left: auto;
    right: 2%;
}

/*-----------------------------
    MENU
-----------------------------*/

nav#nav-static-principal ul li ul {
    margin-top: 0 !important;
}

nav#nav-static-principal ul li ul li a {
    padding-left: 20px !important;
}

.menu.navigation_p {
    background: url(../images/imgd119series/navigation_bg.png) repeat-x scroll center top #333;
}

nav#nav-principal {
    width: 100%;
    margin-top: 15px;
    color: #FFF;
}

nav#nav-principal ul {
    list-style-type: none;
    width: 100%;
    text-align: center;
    margin: auto;
}

nav#nav-principal ul li {
    display: inline;
    color: #fff;
    padding: 15px;
    box-sizing: border-box;
}

nav#nav-principal ul li a {
    font-weight: 700;
    line-height: 3.9em;
    text-decoration: none;
    -webkit-transition-property: color;
    -webkit-transition-duration: .5s;
    -moz-transition-property: color;
    -moz-transition-duration: .5s;
    transition-property: color;
    transition-duration: .5s;
    color: #fff;
    box-sizing: border-box;
}

nav#nav-principal ul li:hover a {
    text-decoration: none;
}

nav#nav-principal ul li ul {
    position: absolute;
    background: lighten(#333, 20%);
    margin-top: -3px;
}

nav#nav-principal ul li ul a {
    color: #fff;
}

div#wrapper nav#nav-static-principal > ul {
    float: left;
    margin-top: 15px;
    margin-right: 20px;
    list-style-type: none;
    width: 18%;
    margin-bottom: 10px;
}

div#wrapper nav#nav-static-principal ul h2,
div#wrapper nav#nav-static-principal ul .h2 {
    font-size: 1.3em;
    margin: 0 0 10px;
    padding: 0 0 5px;
    border-bottom: 2px dotted #bbb;
}

div#wrapper nav#nav-static-principal ul li {
    padding-bottom: 7px;
}

div#wrapper nav#nav-static-principal ul li a {
    text-decoration: none;
    display: block;
    font-weight: 700;
    font-family: "Trebuchet MS", sans-serif;
    transition-property: color;
    transition-duration: .5s;
    padding: 5px 0 5px 5px;
}

body #wrapper #contentpage_left nav#nav-static-principal, body #wrapper #boutiqueaccueil {
    display: block;
}

.menu_burger {
    display: none;
}

div#wrapper nav#nav-static-principal ul li {
    list-style: none;
}

/*-----------------------------
    CONTENU
-----------------------------*/

#wrapper {
    width: 960px;
    margin: 0 auto;
    background: #eee;
    position: relative;
}

#wrapper #footer {
    display: block;
    text-align: center;
    padding: 20px 0;
}

#wrapper #footer a {
    color: #FFF;
}

#content {
    min-height: 500px;
    background: transparent url(../images/imgd119series/dots.png) repeat-x top;
    margin: 30px 10px 10px;
    padding: 0 0 20px;
    position: relative;
}

#contentpage {
    margin-top: 20px;
    position: relative;
}

#contentpage_right {
    width: 78%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: right;
    overflow: hidden;
    position: relative;
}

/*-----------------------------
    PRODUITS
-----------------------------*/

body #fiche-produit .remise-produit {
    height: 38px;
    padding: 14px 0 5px 12px;
    width: 50px;
}

.produits {
    position: relative;
    float: left;
    width: 23%;
    border: 1px solid silver;
    text-align: center;
    margin: 10px 1%;
    padding: 10px;
    box-sizing: border-box;
}

#boutiqueaccueil .produits{
    width: 18%;
    margin: 10px 1%;
}

.produits img {
    margin: 0 !important;
    max-width: 100%;
    height: auto !important;
    display: inline-block;
    box-sizing: border-box;
}

.produits .container-etiquette {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    .produit_etiquette {
        width: 100%;
        box-sizing: border-box;
        left: 0;
        top: 1px;
        position: relative;
    }
}

.produitsaccueil img, .produits img {
    margin: 10px 0;
}

.produits p {
    margin: 5px 0 !important;
}

.produitsaccueil p, .produits p, .galerie p {
    text-align: center;
    font-size: .85em;
}

.produits .prixprod {
    font-size: 1em;
    font-weight: 700;
    margin: 5px 0;
}

.produits .oldprix {
    font-size: .9em;
    text-decoration: line-through;
    color: red;
}

.produits p.titreprod {
    font-size: .9em !important;
    line-height: 1em !important;
}

.produits p.attr {
    height: auto;
}

.produitsaccueil p.attr, .produits p.attr {
    font-weight: 700;
    font-size: .85em;
    height: 30px;
}

.produits p.nomprod, .produits p.titreprod {
    font-weight: 700;
    font-size: 1.1em;
    height: 30px;
}

.produitsaccueil a.addbasket, .produits a.addbasket {
    font-size: .9em;
    cursor: pointer;
    background: url(../images/panier_add.png) no-repeat;
    margin-left: 8px;
    padding: 5px 5px 5px 20px;
    display: inline-block;
}

.produits select {
    width: 100%;
}

.produits .produit_etiquette {
    z-index: 100;
    width: 144px;
}

.produit_etiquette2 {
    bottom: 0;
}

#boutique .is_etiquette .remise {
    top: 170px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 20px;
}

.produits .produit_etiquette {
    height: 26px;
    width: 130px;
    left: 17px;
}

.produit_etiquette:after {
    margin-left: -9px;
    border-width: 9px;
}

.produit_etiquette h3, .produit_etiquette2 h3, .produit_etiquette3 h3,
.produit_etiquette .h3, .produit_etiquette2 .h3, .produit_etiquette3 .h3 {
    padding-top: 5px;
    font-size: 13px;
}

.produits .remise {
    right: 18px;
    top: 87px;
    z-index: 100;
}

.remise {
    position: absolute;
    width: 56px;
    height: 56px;
    background: url(../images/vignette_promo.png) no-repeat top left;
    font-size: 10px;
    top: 30px;
    padding-top: 15px;
    color: #FFF;
    right: -15px;
    display: block;
}

.produitsaccueil {
    float: left;
    height: 260px;
    width: 145px;
    border: 1px solid silver;
    text-align: center;
    margin: 10px;
    padding: 10px;
}

.prixprod {
    font-weight: 700;
    font-size: 1.1em !important;
}

.nomprod {
    font-weight: 700;
    font-size: .9em !important;
    line-height: 1em !important;
}

.ajouter-panier .addbasket {
    cursor: pointer;
    display: block;
    background: url(../images/panier_add.png) no-repeat;
    margin-left: 8px;
    padding: 5px 5px 5px 20px;
    border: none;
}

.button, #paiementchoix .button {
    font-size: 1em;
    clear: both;
    font-weight: 700;
    border: 1px solid #ccc;
    width: auto;
    text-decoration: none;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
}

.button:hover, #paiementchoix .button:hover {
    background: #444;
}

#boutiqueaccueil {
    margin-top: 10px;
    clear: both;
}

.button, #paiementchoix .button {
    font-size: 1em;
    clear: both;
    font-weight: 700;
    width: auto;
    text-decoration: none;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
        display: inline-block;
        .fa {
            margin-right: 10px;
        }
        &.addbasket {
            padding: 10px 10px 10px 30px;
            background-position: 8px 4px;
        }
    }

    .addbasket .fa {
        display: none;
    }

    > .button + .button {
        margin-left: 15px;
    }


}

.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}

/*-----------------------------
    GALERIE / WRAPPER / SLIDER
-----------------------------*/

ul#slider_haut {
    width: 100%;

    margin: 0;
    color: #000;
}

ul#slider_haut li {
    width: 263px;
    padding: 25px;
    display: inline-block;
}

ul#slider_haut li img {
    max-width: 100%;
    height: auto;
    width: auto;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

ul#slider_haut a {
    text-decoration: none;
    font-size: .8em;
    color: #000;
}

ul.menugalerie {
    width: 100%;
    list-style-type: none;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px auto;
    border-top: 1px dotted #bbb;
    border-bottom: 1px dotted #bbb;
}

ul.menugalerie li {
    display: inline;
    padding: 10px;
}

ul.menugalerie li a {
    font-family: arial, sans-serif;
    font-weight: 700;
    text-decoration: none;
    line-height: 2;
    -webkit-transition-property: color;
    -webkit-transition-duration: .5s;
    -moz-transition-property: color;
    -moz-transition-duration: .5s;
    transition-property: color;
    transition-duration: .5s;
    color: #fff;
}

.galerie {
    float: left;
    text-align: center;
    padding: 10px;
    border: 1px solid silver;
}

.galerie_size {
    width: 24%;
}
.galerie_gutter {
    width: 1%;
}
.galerie{
    box-sizing: border-box;
    width: 24%;
    margin: 5px .5%;
}

.galerie img {
    border: 1px solid silver;
    margin: 10px 0;
    height: auto !important;
    max-width: 100% !important;
    width: auto !important;
}

.galerie a {
    color: #000;
}

.diaporama {
    text-align: center;
    text-decoration: none;
    margin-top: 20px;
    padding-top: 20px;
    color: #000;
    border-top: 1px solid #ccc;
}

.diaporama a {
    text-decoration: none;
    color: #000;
}

ul#slider_haut a:hover, .diaporama a:hover {
    text-decoration: underline;
}

.bx-wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 100% !important;
    width: 100%;
}

.bx-wrapper table {
    width: 100% !important;
}

.bx-wrapper li {
    /*width:5% !important;*/
}

/*-----------------------------
    FORMULAIRE
-----------------------------*/

#form1 {
    position: relative;
    padding-top: 10px;
    border: 1px solid silver;
    width: 938px;
    background: #eee;
}

#form1 fieldset {
    border: none;
    float: left;
    display: inline;
    width: 220px;
    margin: 0 0 0 20px;
    padding: 0;
}

#form1 p {
    font-weight: 700;
    margin: .5em 0;
}

#form1 label {
    display: block;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 3px;
}

#form1 input, #form1 textarea {
    width: 202px;
    border: 1px solid #bbb;
    background: #fff url(../images/form1/form_input.gif) repeat-x;
    margin: 3px;
    padding: 5px;
}

#form1 textarea {
    height: 125px;
    width: 640px;
    overflow: auto;
}

#form1 input.button {
    font-size: 1em;
    clear: both;
    font-weight: 700;
    display: block;
    width: 200px;
    text-decoration: none;
    margin-left: 660px;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
}

/*-----------------------------
    LIVRE D'OR
-----------------------------*/

.message_lo {
    position: relative;
    text-align: left;
    margin: 15px 0;
    padding: 15px;
    background: #eee;
    border: 1px solid #ccc;
}

.message_lo p {
    text-align: justify;
    margin-bottom: 5px;
}

.message_lo .message {
    font-style: italic;
    font-size: 1em;
    text-align: justify;
    margin-bottom: 5px;
}

.message_lo p.note {
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: 700;
}

.message_lo p.web {
    font-weight: 700;
    text-align: justify;
    font-size: .8em;
    margin: 10px 0 5px;
}

.message_lo p.web a {
    color: #222;
}

a.addmsglo {
    font-weight: 700;
    display: block;
    width: 250px;
    text-decoration: none;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
}

a.addmsglo:hover, #form1 input.button:hover {
    background: #444;
}

/*-----------------------------
    AJOUT MESSAGE
-----------------------------*/

#addmsg input {
    border: 1px solid #ccc;
    margin-top: 8px;
    margin-bottom: 20px;
    padding: 5px;
}

#addmsg textarea {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    width: 640px;
    padding: 5px;
}

#addmsgleft {
    float: left;
    margin-right: 50px;
}

#addmsg input[type=submit] {
    margin-left: 550px;
    background: #eee;
    border: 1px solid #ccc;
}

#addmsg input[type=submit]:hover {
    background: #fff;
}

#addmsg a, .button.addbasket {
    color: #000;
    text-decoration: none;
}

#addmsg a:hover, .button.addbasket:hover {
    color: #AF9C78;
}

/*-----------------------------
    FOOTER
-----------------------------*/

#wrapper #borderfooter {
    height: 25px;
    background: url(../images/navigation_bg.png) repeat-x scroll center top #333;
}

#fiche-produit {
    .wrap-description {
        h1, .h1 {
            font-size: 24px;
        }
        .brand {
            border: none !important;
            background: transparent !important;
        }
    }
}

/*-----------------------------
    AUTRE
-----------------------------*/

.texthautcontact {
    text-align: center;
    padding-top: 20px;
}

#contactright {
    float: right;
    margin: 40px 0 20px;
}

#contactleft {
    float: left;
    width: 300px;
    margin: 20px 0;
}

#pub {
    background: #ccc;
    width: 728px;
    height: 90px;
    color: #000;
    margin: 0 auto 15px;
}

.clearer {
    clear: both;
}

#GoogleMapAffichage {
    color: #000 !important;
}

.two-column-blog, .one-column-blog {
    padding-top: 15px;
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/
.template_flux_panier.template_flux_panier_1 {
    padding-top: 25px;

    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 95%;
    }

    h4 {
        margin: 0;
    }

    .side-tabs a {
        font-size: 12px;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }

    div#resume .block-price .block-with-background .bloc-total li.content-payment-global,
    div#finish .block-price .block-with-background .bloc-total li.content-payment-global {
        font-size: 16px;
    }

    #finish .content-carriers .logo-carrier-none {
        float: none;
    }
}

#contentfull .template_flux_panier.template_flux_panier_1 .block-reduction-code ul {
    margin: 5px 0;
}

/********************* A GARDER POUR LES MODELS ********************/
/*Gerer les images dans les tableaux*/
.links_p {
    position: relative;
}

#contentpage img {
    max-width: 100% !important;
    height: auto !important;
}

#contentpage .zoomPupImgWrapper > img, #contentpage .zoomWrapperImage > img {
    max-width: none !important;
}

table {
    position: relative;
    table-layout: fixed;
}

td {
    max-width: 50%;
}

#contentpage td img {
    max-width: 100%;
}

#footer.footer_p .links_p td img, #footer.footer_p .links_p img {
    max-width: 100% !important;
}

.links_p table {
    max-width: 100% !important;
}

/*Titre des commentaires du livre d'or ne prend pas toute la largeur*/
.message_lo h4,
.message_lo .h4 {
    width: 80%;
}

.container-comment, .content-cmonsite:not(.form-search) {
    overflow: hidden;
}

/* Commandes */

#order .commandes td{
    padding:10px 0;
    border-top:1px solid rgba(128,128,128,.1);
    .button{
        margin: 5px 0 5px !important;
    }
}
#order .commandes-details{
    > td > div{
        border-bottom:none;
    }
    .infosclient.s6{
        &, &:first-child{
            width: 100%;
            margin:10px 0 0;
        }
    }
}

/******************************************\
|************* MEDIA QUERIES **************|
\******************************************/

@media screen and (max-width: 1030px) {
    #wrapper {
        width: 90%;
        box-sizing: border-box;
    }
    #footer img {
        max-width: 100%;
        height: auto;
    }
    .produits, #boutiqueaccueil .produits{
        width: 31%;
        margin: 0 1% 10px;
        box-sizing: border-box;
    }
    .galerie_size {
        width: 32%;
    }
    .galerie_gutter {
        width: 1%;
    }
    .galerie{
        width: 32%;
        margin: 5px .5%;
    }
    .search-bar {
        width: 95%;
    }
}

@media screen and (max-width: 768px) {
    /*Suppression des blocs en dehors du contenu*/
    .bx-wrapper {
        height: auto;
        width: 80%;
        margin: 10px auto;
    }
    .bx-wrapper a.one_image {
        height: 150px;
    }
    .produits, #boutiqueaccueil .produits {
        width: 48%;
        margin: 0 1% 10px;
        box-sizing: border-box;
    }

    .galerie_size {
        width: 49%;
    }
    .galerie_gutter {
        width: 1%;
    }
    .galerie{
        width: 49%;
        margin: 5px .5%;
    }

}

@media screen and (max-width: 680px) {
    div#wrapper div#headercontent {
        height: auto;
    }
    .slogan {
        left: 0 !important;
        top: 0 !important;
        right: auto !important;
        bottom: auto !important;
        text-align: center !important;
        font-size: 18px !important;
        width: 100% !important;
        position: relative !important;
        padding-bottom: 15px !important;
        float: none !important;
        height: auto !important;
    }
    .logo {
        padding-bottom: 15px;
        position: static !important;
        display: block !important;
        margin: 0 auto !important;
        padding-top: 15px;
        height: auto !important;
        text-align: center;
        width: 100% !important;
        float: none !important;
        img {
            max-height: 70px !important;
            width: auto !important;
            max-width: 100% !important;
            height: auto !important;
            position: static !important;

        }
    }
    .search-bar {
        width: 90%;
    }
    /*Fiche produit*/
    #fiche-produit #formulaire .form-group {
        float: none !important;
    }
    #fiche-produit .input-form-petit {
        width: auto !important;
    }
    #fiche-produit .input-form-moyen {
        width: 100% !important;
    }
    #fiche-produit .wrap-images, #fiche-produit .wrap-description {
        width: 100% !important;
        float: none !important;
    }
    .bx-wrapper table {
        width: 100% !important;
    }
    /*Desactivation du hover sur images produits*/
    .zoomWindow, .zoomPup {
        display: none !important;
    }
    .zoomPup, .zoomPad {
        cursor: initial !important;
    }
    .zoomPad {
        display: inline-block;
        width: 80% !important;
        margin: 0 10% !important;
        text-align: center;
    }
    .zoomPad img {
        width: 100% !important;
        max-height: 100% !important;
        opacity: 1 !important;
    }
    /*Fin desactivation*/
    .produit_etiquette {
        width: 100% !important;
        left: 5%;
        max-width: 100% !important;
    }
    #contentpage_right, div#wrapper nav#nav-static-principal ul {
        float: none;
        width: 100%;
    }
    div#wrapper nav#nav-static-principal ul nav {
        padding-top: 25px;
    }
    .slider_p > li > p {
        display: none !important;
    }
}

@media screen and (max-width: 600px) {
    form .row .col.s3 {
        width: 25%;
    }
    .form-group textarea {
        width: 100% !important;
    }
    .produits {
        width: 46%;
        margin: 0 2% 10px;
    }
    .thumbnail {
        float: left;
        width: 42%;
        margin: 2.5%;
    }
    td {
        max-width: 100%;
    }
    /*Ajuste tous les tableaux des templates*/
    .template table, .template td, .template tbody, .template tr {
        width: 100%;
        display: inline-block;
        margin: 5px auto;
    }
    @import 'design/helper/responsive/mobile/mobile';
    .ui-datepicker {
        width: 98% !important;
        font-size: .75em;
        left: 0 !important;
    }
    nav#nav-principal li {
        width: 50%;
    }

}

@media screen and (max-width: 500px) {
    .message_lo {
        margin: 15px 5px;
    }
    .search-bar {
        width: 85%;
    }
    #panier {
        right: 0 !important;
        width: 100% !important;
        box-sizing: border-box;
    }
    .ui-datepicker {
        left: 0 !important;
    }
    nav#nav-principal li {
        width: 100%;
    }
    .bx-wrapper {
        width: 70%;
    }
}

@media screen and (max-width: 400px) {
    #wrapper {
        width: 95%;
        box-sizing: border-box;
    }
    .produits, #boutiqueaccueil .produits {
        width: 75% !important;
        margin: 0 auto 12px !important;
        float: none;
    }
    .galerie_size {
        width: 99%;
    }
    .galerie_gutter {
        width: 1%;
    }
    .galerie{
        box-sizing: border-box;
        width: 99%;
        margin: 5px .5%;
    }
    .thumbnail {
        float: none;
        width: 75%;
        margin: 0 auto 12px;
        padding: 15px;
    }
    .content-payment-global {
        font-size: 15px !important;
    }
}

@media screen and (max-width: 360px) {
    #content .in {
        padding: 15px 8px;
    }
    ul.sous_navigation {
        margin: 15px 0 15px 10px;
    }
    .message_lo {
        margin: 15px 0;
    }
    #content form {
        overflow: hidden;
    }
    form .row .col {
        padding: 0 2px;
    }
    form .row .col.s3 {
        width: 50%;
    }
}

@media screen and (max-width: 320px) {

}

@media screen and (min-width: 680px) {
    nav#nav-principal li:hover > ul {
        display: block !important;
    }
}

@media screen and (max-width: 680px) {
    .menu.navigation_p {
        background-repeat: repeat;
    }
    .menu_burger {
        text-align: center;
    }
    body #wrapper #contentpage_left nav#nav-static-principal {
        display: none;
    }
    /* Nav mobile */
    div#wrapper div#header nav#nav-principal ul li ul {
        margin-top: 0;
        top: 100%;
    }
    div#wrapper div#header nav#nav-principal ul li {
        float: left;
        z-index: auto !important;
        position: relative;
        width: 100% !important;
        padding: 0;
    }
    nav#nav-principal.nav_fermee > ul {
        display: none;
    }
    .hasDropdown {
        display: block !important;
        opacity: 1 !important;
    }
    nav.principal {
        text-align: left;
        margin: 10px auto;
        padding: 0;
        position: relative;
        width: 95%;
    }
    nav.principal a {
        padding: 4px 24px;
    }
    #nav ul.menu li a {
        height: 40px;
        width: 90px;
        display: block;
        margin: 0 auto;
        position: relative;
        z-index: 10000;
    }
    #nav ul.menu > li {
        border: none;
        width: 100%;
        display: block;
        box-sizing: border-box;
    }
    #nav ul.menu li.contact {
        position: static;
        top: 0;
        right: 0;
        width: 100%;
    }
    #nav ul.menu li:hover a {
        color: #fff;
    }
    .menu_burger {
        display: block;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 5px;
        width: 100%;
        position: relative;
        z-index: 9999;
        box-sizing: border-box;
    }
    .menu_burger::before {
        font-family: FontAwesome, sans-serif;
        content: "\f0c9";
        margin-right: 5px;
    }
    nav.principal > ul {
        display: none;
    }
    #nav-principal > ul {
        display: none;
    }
    div#wrapper div#header {
        height: auto;
    }
    #nav-principal.nav_ouverte > ul {
        display: block;
        position: relative;
        width: 100%;
        z-index: 9999;
        box-sizing: border-box;
    }
    nav#nav-principal li {
        float: none;
        margin-right: 0;
        display: block;
        position: relative;
        box-sizing: border-box;
    }
    nav#nav-principal li a {
        padding: 8px 8px;
        display: block;
        height: auto;
        float: none;
        margin: 0;
    }
    nav.principal ul li a span {
        font-size: 17px;
    }
    .hasDropdown ul + span::after {
        font-family: FontAwesome, sans-serif;
        content: "\f0d7";
    }
    .dropDown {
        position: absolute;
        right: 2%;
        top: 8px;
        font-weight: bold;
        display: block;
        height: 25px;
        width: 8%;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        padding-top: 5px;
        background-color: transparent !important;
    }
    nav.principal > ul > li ul {
        display: block;
        position: relative;
        text-align: left;
        border: none;
        opacity: 1;
        top: 65px;
        height: auto !important;
        width: 100% !important;
        visibility: visible !important;
        background: lighten(#333, 20%);
    }
    nav.principal li ul li a {
        padding: 5px 10px 10px 30px;
        color: #fff !important;
    }
    nav.principal li ul li {
        border-bottom: none;
    }
    nav#nav-principal > ul > li > a.current, nav#nav-principal li a:hover {
        background: transparent;
    }
    nav#nav-principal li a {
        width: 100%;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
    }

    #pages {
        display: block;
        width: 90% !important;
        position: absolute;
        right: 0;
        top: 40px;
    }
    #nav ul#pages li a {
        width: 100% !important;
        height: 37px !important;
        padding: 10px 0;
    }
    nav#nav-principal li {
        width: 95% !important;
        border: none !important;
    }
    .sousmenu_ouvert {
        z-index: 99999 !important;
    }
    nav#nav-principal ul li.sousmenu_ouvert > ul {
        display: block;
        position: absolute;
        z-index: 999;
        left: 0;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    nav#nav-principal ul li.sousmenu_ouvert > ul li a {
        float: left;
        margin: 5px;
        color: #fff;
    }
    nav#nav-principal ul li.sousmenu_ouvert > ul li a:hover {
        text-decoration: none;
        color: #fff;
    }
    /* FIN Nav mobile */

}

.nav-static-breadcrumb {
    margin-top: 15px;
    margin-bottom: 10px;
    a {
        color: #000;
    }
}

.nav-static-breadcrumb li:not(:last-child)::after {
    content: ' > ';
}
#fiche-produit .wrap-description .ajouter-panier .quantite{
    margin-right: 0;
}
.wrap-description .bloc-quantite .bloc-bt-quantity .bt-quantity{
    height: auto;
}